import store from '@/store'
import translations from '@/assets/translations'
const locales = {
    'en-us': 'en_US',
    'ar-sa': 'ar_SA'
}
var translationMixin = {
    filters: {
        name(value) {
            if (typeof value === 'object') {
                return value[store.getters['app/getLocale']];
            }
            return value;
        },
        modifiers(value) {
            const vm = translationMixin
            return value.map(modifier => {
                return vm.methods.name(modifier.name)
            }).join(', ')
        },
        price(value) {
            if(value) {
                const vm = translationMixin
                return `+ ${vm.methods.translateNumber(value)} ${vm.methods.translate(store.getters['concept/currency'].key, store.getters['concept/currency'].value)}`
            }
        },
        itemPrice(value) {
            if(value) {
                const vm = translationMixin
                return `${vm.methods.translateNumber(value)} ${vm.methods.translate(store.getters['concept/currency'].key, store.getters['concept/currency'].value)}`
            }
        },
        cal(value) {
            if(value && value > 0) {
                const vm = translationMixin
                return `(${value} ${vm.methods.translate('cal', 'cal')})`
            }
        },
        charAt(value, i = 0) {
            return value.charAt(i)
        }
    },
    computed: {
        total() {
            return store.getters['cart/items'].map(({total, quantity}) => {
                return total * quantity
            }).reduce((a,b) => { return a + b }, 0)
        },
        cartItems() {
            return store.getters['cart/items'].map(({total, quantity}) => {
                return quantity
            }).reduce((a,b) => { return a + b }, 0)
        }
    },
    methods: {
        translate(key ='' , defaultText = '') {
            let text = translations.data[0]?.fields?.[key]?.[locales[store.getters['app/getLocale']]]
            if(text) {
                return text
            }
            return defaultText;
        },
        translateNumber(num, ignoreLocaleString = false) {
            const locale = locales[store.getters['app/getLocale']].toLowerCase();
            const formatDecimal = (value, override) => {
                const decimalPlaces = store.getters['concept/getConcept']['decimal-places'];
                return locale === 'en_us' ? Number(value).toFixed(override || decimalPlaces) : value;
            };
            if (!num) {
                return 0;
            }
            if (ignoreLocaleString && locale === 'en_us') {
                return formatDecimal(num);
            } else {
                return Number(num).toLocaleString(locale === 'en_us' ? 'en-US' : 'ar-EG');
            }
        },
        total_vat_included(text, total) {
            return text.replace('_TOTAL_ TOTAL', total)
        },
        name(value) {
            return value[store.getters['app/getLocale']];
        },
    }
}

export default translationMixin
